<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    import Swal from "sweetalert2";
    import axios from "axios";
    // import Swal from "sweetalert2"
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Tambah Kota",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Tambah Kota",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Kota",
                        href: "/master/dokumen-persyaratan",
                    },
                    {
                        text: "Tambah Kota",
                        active: true,
                    },
                ],
                axiosCatchError: null,
                // variable Page
                master_daerah: [],
                provinsi_selected: [],
                kota_kode: null,
                kota_nama: null,

            };
        },
        mounted() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            // Select Chain Master Profinsi
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-daerah",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then((response) => {
                    this.master_daerah = response.data.data.referensi;
                    console.log(this.master_daerah);
                    Swal.close()
                })
                .catch(e => {
                    this.axiosCatchError = e.response.data.data
                    console.log(this.axiosCatchError)
                    Swal.close()
                })
        },
        methods:{
            // Method dari masing masing fungsi di dalam file ini
            StoreData() {
                let self = this;
                Swal.fire({
					title: '<i class="fas fa-spinner fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
                axios
                .post(process.env.VUE_APP_BACKEND_URL_VERSION + "master/kota", {
                        kota_prov_id: self.provinsi_selected?.id,
                        kota_kode: self.kota_kode,
                        kota_nama: self.kota_nama,
                    },
                    {headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    }
                })
                .then(function (response) {
                    console.log(response)
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master kota segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-kota" });
                        }
                    });
                }).catch(e => {
                    this.axiosCatchError = e.response.data.data
                    Swal.close();
                })
			},
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                    <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                        {{axiosCatchError}}
                                    </div>
                                    <div class="alert alert-danger" role="alert" v-else>
                                        <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                            <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                                <span class="text-danger">{{ allErrors[0]}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Provinsi" label-for="formrow-provinsi-input">
                                        <v-select :options="master_daerah" label="prov_nama" v-model="provinsi_selected"></v-select>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Kode Kota" label-for="formrow-kode-kota-input">
                                        <b-form-input id="formrow-kode-kota-input" type="text" v-model="kota_kode"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Nama Kota" label-for="formrow-nama-kota-input">
                                        <b-form-input id="formrow-nama-kota-input" type="text" v-model="kota_nama"></b-form-input>
                                    </b-form-group>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button variant="primary" type="submit"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
